@import "./FormBaseStyles.scss";

.form-action-section {
    display: flex;

    .form-action-section__left {
        flex: 0 0 $form-indent-normal;
        display: flex;
        justify-content: center;

        &.form-action-section--alignment-full {
            > * {
                flex: 1 1 auto;
            }
        }
        &.form-action-section--alignment-left {
            justify-content: left;
            > * {
                flex: 0 0 content;
            }
        }
        &.form-action-section--alignment-center {
            > * {
                flex: 0 0 content;
            }
        }
        &.form-action-section--alignment-right {
            justify-content: right;
            > * {
                flex: 0 0 content;
            }
        }
    }

    .form-action-section__middle {
        flex: 0 0 $form-base-fields-width;

        display: flex;
        justify-content: center;

        &.form-action-section--alignment-full {
            > * {
                flex: 1 1 auto;
            }
        }
        &.form-action-section--alignment-left {
            justify-content: left;
            > * {
                flex: 0 0 content;
            }
        }
        &.form-action-section--alignment-center {
            > * {
                flex: 0 0 content;
            }
        }
        &.form-action-section--alignment-right {
            justify-content: right;
            > * {
                flex: 0 0 content;
            }
        }
    }

    .form-action-section__right {
        flex: 1 1 0;

        display: flex;
        justify-content: center;

        &.form-action-section--alignment-full {
            > * {
                flex: 1 1 auto;
            }
        }
        &.form-action-section--alignment-left {
            justify-content: left;
            > * {
                flex: 0 0 content;
            }
        }
        &.form-action-section--alignment-center {
            > * {
                flex: 0 0 content;
            }
        }
        &.form-action-section--alignment-right {
            justify-content: right;
            > * {
                flex: 0 0 content;
            }
        }
    }

    @include form-breakpoint-laptop {
        flex-wrap: wrap;

        .form-action-section__right {
            margin-top: 10px;
            flex-basis: 100%;
        }
    }

    @include form-breakpoint-tablet {
        flex-direction: column;
        flex-wrap: wrap;

        .form-action-section__left,
        .form-action-section__middle,
        .form-action-section__right {
            width: 300px;
            flex: 0 0 auto !important;
            align-self: center;
            margin-bottom: 10px;

            > * {
                flex: 1 1 auto !important;
            }
        }

        .form-action-section__right {
            margin-top: 0;
        }
    }
}
