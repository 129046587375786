@import "../../../scss/partials/all.scss";

#login {
    #login-btn {
        padding: 0px;
        border: none;
        opacity: 1;
        cursor: pointer;
        background-color: unset !important;
        height: auto;
        /*background-image: url("../../images/swiipe-button.png");
        background-size: 306px 45px;
        height: 45px;
        width: 306px;*/
    }
    .form-group-login-btn {
        margin-bottom: 40px;
    }

    #forgotpw-link {
        font-size: 13px;
        text-decoration: none;
        padding-top: 5px;
        text-decoration: none;
        border-bottom: 1px dotted #797676;
        &:hover {
            text-decoration: none;
            color: #b3b3b3;
            border-bottom: 1px dotted #797676;
        }
    }

    .panel-body {
        margin-bottom: 45px;
    }

    #signup-link,
    #keepsignin-label {
        color: $gray-font;
        font-weight: 400;
    }

    #forgotpw-link,
    #keepsignin-label {
        color: $gray-font;
        font-weight: 400;
    }
    .logo-img {
        width: 100%;
        height: auto;
    }
}
