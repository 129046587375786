.onboarding-shared-pricing {
    .onboarding-shared-pricing-wrapper {
        .text-thin {
            font-size: 1.3em !important;
        }

        .text-blue {
            font-size: 1.3em !important;
        }

        .terms-link {
            a {
                color: #23638e !important;
            }
        }

        .onboarding-price-container {
            padding: 25px 0;
            background-color: #f9f9f9;
            font-size: 18px;

            .onboarding-price--transaction-fees {
                font-size: 16px;
                font-style: italic;
            }

            .line-two {
                font-size: 1em;
                line-height: 33px;
            }
        }
    }

    .applepay-logo {
        border: #414141;
        border-style: solid;
        border-radius: 10px;
        border-width: 2px;
    }
}
