.modal-a2a-refund {
    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    iframe {
        border: none;
        flex: 1 1 0;
        min-height: 600px;
        width: 100%;
    }
}
