@import "../../scss/partials/all";

.unordered-hints-list-comp {
    .heading {
        font-weight: 600;
        font-size: 18px;
    }

    ul {
        padding-left: 15px;
    }
}
