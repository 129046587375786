@import "../../../styles//partials//all";

.company-name-cvr-input {
    .text-btn {
        color: $gray-font;
        border-bottom: 1px dotted $gray-font;
        cursor: pointer;
        font-size: 14px;
        display: inline-block;
    }

    .clearfix {
        clear: both;
    }

    .search-container {
        width: 100%;
        background: white;
        padding: 15px 3px 0 3px;
        z-index: 9;
        border: 1px solid lightgrey;
        position: absolute;
        border-radius: 3px;

        .select-manually {
            display: flex;
            align-items: center;
            border-top: 1px solid lightgrey;
            margin-top: 15px;
            padding: 9px 12px;
            cursor: pointer;
            font-size: 16px;

            .text {
                margin-left: 4px;
            }

            .icon {
                height: 16px;
                width: 16px;
                background-size: 16px;
            }
        }

        .wrapper {
            /*padding: 15px 0;*/
            max-height: 125px;
            overflow-x: auto;

            .option {
                color: $gray-font;
                padding: 9px 12px;
                cursor: pointer;

                .cityname {
                    font-style: italic;
                }

                &:hover {
                    background: $gray-background;
                }
            }

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border-radius: 20px;
            }

            &::-webkit-scrollbar-thumb {
                background: #989898;
                border-radius: 20px;
            }
        }
    }
}
