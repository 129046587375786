.plussell-disclaimer-container {
    .plussell-form-head {
        text-align: center;
        margin: 20px 0 30px 0;

        .plussell-logo {
            width: 190px;
        }
    }

    .plussell-info {
        padding: 0 14%;
    }

    @media screen and (max-width: 685px) {
        .plussell-info {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 450px) {
        .plussell-info {
            padding: 0 5%;
        }
    }

    @media screen and (max-width: 380px) {
        .plussell-info {
            padding: 0;
        }
    }
}
