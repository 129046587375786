@import "../../../scss/partials/_colors.scss";

#partner-heading span {
    &:nth-child(1) {
        font-size: 17px;
        text-align: center;
        display: block;
        margin: 30px 0;
    }
    &:nth-child(2) {
        font-weight: 100;
        color: $gray-font;
    }
}
