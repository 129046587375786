.table-container__spinner-container {
    min-height: 100px;

    .table-container__main-title-row {
        display: flex;
        align-items: center;
        margin: 10px 0;

        @media screen and (max-width: 870px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .table-container__main-title-row-title {
            color: #1a496d;
            font-weight: 600;
            font-size: 28px;
            margin: 0;
            flex: 0 0 auto;
        }

        .table-container__search-box {
            display: inline-block;
            padding: 0 !important;
            margin: 20px 0 15px 10px;
            flex: 1 1 auto;

            input {
                min-width: 335px;
                height: 1.8em;
            }

            @media screen and (max-width: 870px) {
                margin: 5px 0 -90px 0px;

                input {
                    min-width: 290px;
                }
            }

            @media screen and (max-width: 400px) {
                margin-bottom: 0;
            }

            @media screen and (max-width: 355px) {
                width: 100%;

                input {
                    min-width: unset !important;
                }
            }
        }

        .table-container__search-box-to-overview {
            line-height: 30px;
            white-space: nowrap;
            cursor: pointer;
            color: #23638e;
            text-decoration: underline;
            font-weight: 300;
            margin-left: 10px;

            @media screen and (max-width: 985px) {
                position: absolute;
                top: 50px;
                left: 95px;
            }

            @media screen and (max-width: 870px) {
                top: 40px;
                left: 295px;
            }

            @media screen and (max-width: 650px) {
                top: 40px;
                left: 165px;
            }
            @media screen and (max-width: 375px) {
                top: 5px;
                left: unset;
                right: 0;
            }
        }

        .w-100 {
            width: 100%;
        }
    }

    .table-container__pagination {
        margin-top: 25px;
        margin-bottom: 15px;
    }

    .table-container__tabs_row {
        display: flex;
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 15px;

        margin-left: -30px;
        margin-right: -30px;
        padding-left: 20px;

        .table-container__tabs {
            font-size: 15px;
            margin: 0 10px;
            padding-bottom: 10px;
            font-weight: 600;
            color: #bababa;
            cursor: pointer;

            &.table-container__tabs--active {
                color: #607995;
                border-bottom: 2px solid;
            }
        }

        @media screen and (max-width: 945px) {
            flex-direction: column;

            .table-container__tabs {
                font-size: 13px;
                max-width: 115px;
                margin-bottom: 10px;
            }
        }

        @media screen and (max-width: 870px) {
            margin-top: 50px;
        }

        @media screen and (max-width: 400px) {
            margin-top: 0;
        }

        @media screen and (max-width: 340px) {
            border-bottom: 1px solid #dfdfdf;
            margin-bottom: 15px;

            margin-left: -5px;
            margin-right: -5px;
            padding-left: 5px;
        }
    }

    .table-container__controls-box {
        display: flex;
        justify-content: space-between;
        line-height: 30px;

        @media screen and (max-width: 810px) {
            flex-direction: column;
        }

        @media screen and (max-width: 435px) {
            .controls-box {
                .bulk-actions-and-filter-box {
                    flex-direction: column;
                }

                .filter-button-box {
                    margin-left: 0;
                    margin-top: 5px;
                }
            }
        }

        .table-container__filter-button-box {
            margin-left: 15px;

            .table-container__filter-button {
                float: right;
                border: 1px solid #d7d7d7;
                color: #4d4d4d;
                padding: 0px 15px;
                border-radius: 3px;
                cursor: pointer;

                img {
                    margin-right: 6px;
                }

                @media screen and (max-width: 810px) {
                    float: left;
                }
            }

            @media screen and (max-width: 435px) {
                margin-left: 0;
                margin-top: 5px;
            }
        }

        .table-container__bulk-actions-and-filter-box {
            display: flex;

            @media screen and (max-width: 435px) {
                flex-direction: column;
            }
        }

        .table-container__refresh-icon {
            display: inline;
            margin-right: 30px;
            cursor: pointer;
            img {
                width: 30px;
            }

            &.table-container__refresh-icon--refresh-animating img {
                -webkit-animation: refresh-spin 2s linear infinite;
                -moz-animation: refresh-spin 2s linear infinite;
                animation: refresh-spin 2s linear infinite;
            }

            @-moz-keyframes refresh-spin {
                100% {
                    -moz-transform: rotate(-360deg);
                }
            }
            @-webkit-keyframes refresh-spin {
                100% {
                    -webkit-transform: rotate(-360deg);
                }
            }
            @keyframes refresh-spin {
                100% {
                    -webkit-transform: rotate(-360deg);
                    transform: rotate(-360deg);
                }
            }
        }
    }

    .table-container__table {
        width: 100%;
        font-size: 1.1em;

        .table-container__empty-message {
            font-size: 16px;
            margin-bottom: 20px;
        }

        .actions-column-header {
            max-width: 160px;
        }

        .table-container__table-header-cell {
            height: 50px;
            font-size: 1em;
        }

        tr {
            height: 55px;
            border-top: 1px solid #dfdfdf;

            &:nth-child(1) {
                border: none;

                &:hover {
                    background-color: unset;
                    cursor: unset;
                }
            }

            &:hover,
            &.selected {
                background-color: #f5f5f5;
            }

            .table-container__table-row-cell:hover {
                cursor: pointer;
            }

            .table-container__table-row-cell:last-child {
                cursor: unset;
            }
        }

        .table-container__table-header-cell,
        .table-container__table-row-cell {
            min-width: 30px;

            //checkbox col
            &.table-container__table-row-cell-select,
            &.table-container__table-header-cell--select {
                min-width: 30px;
                width: 30px;
                text-align: center;

                input {
                    position: initial;
                    margin: 7px 0 0 0;
                }
            }
        }

        .table-container__table-row-cell {
            font-weight: 300;

            &.table-container__table-row-cell-select {
                margin: 7px 0 0;
                position: static;
            }
        }
    }

    @media screen and (max-width: 1130px) {
        .table-container {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            .table-container__table-row-cell,
            .table-container__table-header-cell {
                min-width: 100px;
            }
        }
    }
}
