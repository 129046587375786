@import "../../../scss/partials/all.scss";

#partner-signup {
    &.center-form {
        width: $center-form-width-desktop-partner !important;

        @media screen and (max-width: $breakpoint-mobile-m1) {
            width: 100% !important;
        }

        @media screen and (max-width: $breakpoint-tables) {
            width: 100% !important;
            padding: 2rem 3rem !important;
        }
    }

    #partner-signup-btn {
        padding: 0px;
        border: none;
        opacity: 1;
        cursor: pointer;
        background-color: unset !important;
        height: auto;
        margin-bottom: -15px;

        img {
            width: 100%;
            height: auto;
            padding: 0px 35px;
        }
    }

    .swiipe-accept-terms {
        text-align: left !important;
    }

    ul {
        padding-left: 15px;
    }
}
