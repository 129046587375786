.merchant-dashboard-footer {
    display: flex;
    justify-content: flex-end;
    margin: 0 20px;

    .export-text {
        align-self: center;
        margin-right: 15px;
        font-size: 14px;
    }

    select {
        flex: 0 1 auto;
        width: auto;
        margin-right: 20px;
    }

    button {
        flex: 0 1 auto;
        width: auto;
    }
}