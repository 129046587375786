.swish-id-step {
    .swish-id-form {
        display: flex;
        margin-top: 40px;
        padding: 0 15%;
        align-items: center;
        flex-direction: column;

        .swish-id-input {
            width: 100%;
        }

        .swish-id-text {
            font-size: 1.2em;
            color: #1a496d;
            margin-bottom: 5px;
            display: block;
        }

        .submit-btn {
            max-width: 280px;
            width: 50%;
            margin-top: 3em;
        }
    }

    @media screen and (max-width: 685px) {
        .swish-id-form {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 475px) {
        .swish-id-form {
            margin-top: 40px;
            padding: 0 5%;

            .submit-btn {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 380px) {
        .swish-id-form {
            padding: 0;
        }
    }
}
