.payment-link-send-modal {
    .modal-pl-custom {
        max-width: 710px !important;
    }

    .modal-body {
        padding-top: 0;

        .title {
            font-size: 1.2em;
            text-align: center;
            color: #1a496d;
        }

        .main-container {
            .customer-data-preview {
                display: flex;
                flex-direction: column;

                color: #555555;
                background-color: #eef0f6;
                border-radius: 5px;
                padding: 10px 20px;
                margin-bottom: 30px;

                .column {
                    display: flex;

                    &:last-child > .left {
                        border-bottom: none;
                        padding: 0;
                        margin: 0;
                    }

                    .left {
                        border-bottom: 1px solid #d7d7d7;
                        flex-grow: 1;

                        padding-bottom: 5px;
                        margin: 0 10px 10px 0;

                        span {
                            font-style: italic;
                        }
                    }

                    .right {
                        display: flex;
                        align-items: center;
                        justify-content: right;

                        flex-basis: 60px;
                    }
                }
            }

            .alert {
                .text-btn {
                    color: inherit;
                }

                //read more
                &.alert-warning {
                    margin: 20px 0 0 0;
                }

                //error messages
                &.alert-danger {
                    margin-top: 20px;
                }
            }

            .preview-buttons {
                justify-content: flex-end;
            }
        }
    }

    .modal-body {
        .main-container {
            padding: 0 25%;

            @media screen and (max-width: 685px) {
                padding: 0 10%;
            }

            @media screen and (max-width: 450px) {
                margin-top: 40px;
                padding: 0 5%;

                .submit-btn {
                    max-width: 240px;
                }
            }

            @media screen and (max-width: 380px) {
                padding: 0 5%;

                .submit-btn {
                    max-width: 200px;
                }
            }
        }
    }
}
