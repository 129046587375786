.title-section {
    display: flex;
    align-items: center;
    margin: 10px 0;

    &.smaller {
        p {
            font-size: 20px !important;
        }
    }

    &.center {
        p {
            text-align: center;
        }
    }

    p {
        color: #1a496d !important;
        font-weight: 600;
        font-size: 28px !important;
        margin: 0;
    }
}
