$clearhaus-base-fields-width: 435px;
$clearhaus-hints-margin-right: 45px;
$clearhaus-hints-title-width: 155px;
$clearhaus-documentation-left-margin: 140px;
$clearhaus-indent-normal: 320px;
$clearhaus-indent-smaller: 180px;

@mixin clearhaus-breakpoint-laptop {
    @media screen and (max-width: 1110px) {
        @content;
    }
}

@mixin clearhaus-hint-breakpoint-laptop {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin clearhaus-breakpoint-tablet {
    @media screen and (max-width: 945px) {
        @content;
    }
}

@mixin clearhaus-footer-left-button-styles {
    width: 130px;

    @include clearhaus-breakpoint-tablet {
        max-width: 300px;
        width: 100%;
    }
}

@mixin clearhaus-footer-main-button-styles {
    @include clearhaus-breakpoint-tablet {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        max-width: 300px;
    }
}

@mixin clearhaus-footer-right-button-styles {
    display: flex;
    justify-content: center;

    button {
        width: 130px;
    }

    @include clearhaus-breakpoint-laptop {
        margin-left: 95px;
        justify-content: start;
    }

    @include clearhaus-breakpoint-tablet {
        max-width: 300px;
        margin: 0 auto;
        width: 100%;

        & > div,
        button {
            width: 100%;
        }
    }
}

// TODO: Get rid of bad code breaking component abstraction
@mixin clearhaus-fields-width-base {
    input,
    select,
    textarea,
    .flag-container,
    .alert.alert-danger,
    .alert.alert-warning,
    .text-normal,
    .input-group,
    .swiipe-file-upload,
    .file-option,
    ul {
        max-width: $clearhaus-base-fields-width;
    }
}

.clearhaus-onboarding-form {
    @include clearhaus-fields-width-base;

    .clearhaus-onboarding-form__sales-message-header span {
        padding-top: 20px;
        font-size: 14px;
        @include clearhaus-breakpoint-tablet {
            padding-top: 0;
        }
    }
    .clearhaus-onboarding-form__sales-message {
        background-color: #fff3cd;
        padding: 15px 20px;
        font-size: 14px;
        max-width: $clearhaus-base-fields-width;
        font-style: italic;
    }

    .clearhaus-onboarding-form__documentation-fields-section .alert-danger, //Error messages
    .clearhaus-onboarding-form__documentation-comments {
        margin-left: $clearhaus-documentation-left-margin;

        @include clearhaus-breakpoint-tablet {
            margin-left: 0;
        }
    }

    //TODO: make it a component?
    .text-normal {
        color: #555555;
        font-size: 16px;
        font-weight: 300;

        a {
            color: #112e44;

            &:hover {
                color: #1a496d;
            }
        }
    }

    .clearhaus-onboarding-form__footer {
        .clearhaus-onboarding-form__go-back-button {
            @include clearhaus-footer-left-button-styles;
        }

        .clearhaus-onboarding-form__submit-btn {
            @include clearhaus-footer-main-button-styles;
        }

        .clearhaus-onboarding-form__save-btn-container {
            @include clearhaus-footer-right-button-styles;
        }

        .clearhaus-onboarding-form__form-saved-msg {
            font-size: 16px;
            width: fit-content;
            margin: 0 auto;
            color: #4f8c51;

            margin-top: 12.5px;
            margin-bottom: 12.5px;

            img {
                margin-right: 5px;
                max-width: 20px;
            }
        }
    }

    @include clearhaus-breakpoint-laptop {
        .clearhaus-onboarding-form__footer {
            .clearhaus-onboarding-form__form-saved-msg {
                margin-left: 120px;
            }
        }
    }

    @include clearhaus-breakpoint-tablet {
        .clearhaus-onboarding-form__footer {
            .clearhaus-onboarding-form__form-saved-msg {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
