#resurs-onboarding__not-ready-step {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;

    .resurs-onboarding__apply-account-btn {
        width: fit-content;
        margin: 3em 0;
    }

    .resurs-onboarding__apply-for-resurs-info {
        font-size: 16px;
    }

    .resurs-onboarding__apply-for-resurs-notice {
        font-size: 16px;
        color: #495057;
        margin-top: 1em;
        margin-bottom: 3em;
    }

    hr {
        margin: 0;
        min-width: 100%;
    }
}
