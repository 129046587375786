@import "./FormBaseStyles.scss";

.form-field-section {
    position: relative;

    .form-field-section__header {
        position: absolute;
        top: 30px;
        left: 95px;

        display: flex;
        align-items: center;

        span {
            font-weight: 600;
            margin-left: 10px;
            max-width: 200px;
        }

        &--font {
            &-normal {
                span {
                    font-size: 18px;
                }
            }

            &-smaller {
                span {
                    font-size: 15px;
                }
            }
        }
    }

    .form-field-section__form-fields {
        display: flex;

        &--indent {
            &-normal {
                div.indent {
                    flex: 0 0 $form-indent-normal;
                }
            }

            &-smaller {
                div.indent {
                    flex: 0 0 $form-indent-smaller;
                }
            }
        }

        .fields {
            flex-grow: 1;
        }
    }

    &__folded {
        .form-field-section__header {
            display: none;
        }

        .form-field-section__form-fields {
            div.indent {
                flex: 0 0 $form-indent-smaller;
            }

            p {
                margin: 0;
            }
        }
    }

    .form-field-section__form-errors {
        max-width: $form-base-fields-width;
    }

    @include form-breakpoint-tablet {
        .form-field-section__header {
            position: initial;
            max-width: $form-base-fields-width;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        .form-field-section__form-fields {
            flex-direction: column;
            max-width: $form-base-fields-width;
            margin: 0 auto;
            div.indent {
                flex: 0 0 auto;
            }
        }
    }
}
