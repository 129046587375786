.resurs-onboarding-store-radio {
    display: flex;
    justify-content: space-between;
    padding: 0px 5em;
    margin: 1em 0;
    flex-wrap: wrap;

    .resurs-onboarding-store-radio__radio-button {
        margin-top: 1em;
    }

    .resurs-onboarding-store-radio__information {
        margin-left: 0.5em;
        .resurs-onboarding-store-radio__store-name {
            font-size: 17px;
        }

        .resurs-onboarding-store-radio__country {
            font-size: 16px;
            font-style: italic;
        }
    }

    .resurs-onboarding-store-radio__payment-types {
        font-size: 16px;
        flex-basis: 13em;

        @media screen and (max-width: 730px) {
            font-style: normal;
            font-weight: normal;
            margin-left: 7px;
        }
    }

    &.resurs-onboarding-store-radio--selected {
        font-weight: bold;
    }
}
