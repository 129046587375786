@import "../../scss/partials/all.scss";

#plugins-top,
#plugins-middle,
#plugins-bottom {
    p {
        font-size: 13px;
        color: $blue-main;
        margin: 0;
    }

    a {
        color: #797979;
        border-bottom: 1px dotted #797979;
        text-decoration: none;

        &:hover {
            color: $blue-link-hover;
        }
    }

    .plugin-main-logo {
        height: auto;
        margin: 0px 5px 0px 0px;
        padding-right: 5px;
    }

    .smaller-text {
        font-size: 13px;
    }

    .no-padding {
        padding: 0;
        font-size: 0.9em;
    }

    .plugins-expand-section {
        font-size: 1.1em;
        text-align: left;
        display: block;
        width: 100%;
    }

    .plugins_section_arrows {
        margin-top: 10px;
        margin-bottom: 0px !important;
        height: 20px;
    }
    .plugins-expand-section .plugins_section_arrows .icon {
        margin-left: auto;
        margin-right: auto;
        height: 11px;
        padding: 20px;
        background-position: center;
    }

    .services-title {
        p {
            text-align: center;
            color: #797676;
        }
    }

    .plugins-expand-section .expand-section-hidden {
        display: none;
    }

    .plugins-expand-section .expand-section-content {
        display: block;
        text-align: justify;
    }

    .onboard-status-approved {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        p {
            font-family: Open Sans;
            font-size: 13px;
            text-align: center;
            font-weight: 300;
            color: #797676;
            white-space: nowrap;
        }
        button {
            margin-top: 10px;
        }

        .text-btn-edit {
            font-size: 12px;
            margin-top: 10px;
        }
    }
    .plugins-separator {
        position: relative;
        margin-top: 2rem;

        &:before {
            display: block;
            position: absolute;
            content: "";
            top: -1rem;
            width: 100%;
            border: 1px solid $gray-border;
            opacity: 0.2;
        }
    }

    .swiipe-outer-container .swiipe-standard-button.small-btn {
        font-size: 14px;
        height: 35px;
    }

    .plugin__terms-accepted {
        background-color: rgba(175, 238, 174, 0.25);
        color: #258e23;
        text-align: center;
        font-size: 12px;
        margin-top: 18px;
        padding: 3px;
        font-style: italic;
    }

    .price-container {
        flex: 0.4 0 50px;

        &.more-text {
            flex: 0.4 0 143px;
        }
        .service-price {
            color: #797979;
            font-size: 1.1em;

            &.sub-text {
                font-size: 1em;
            }
        }
    }

    .plugin-row-item {
        padding: 25px 15px;
        background: rgba(242, 242, 242, 0.5);
        margin-bottom: 10px;
    }

    .service-row-item {
        padding: 15px 5px 15px 5px;
    }

    .plugin-logo-box {
        flex: 8;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .plugin-container__card-logos {
            .card-logos--image {
                display: inline-block;
                width: 45px;
            }
        }
        .plugin-container__additional-logos {
            display: flex;
            color: black;
            font-size: 10px;
            flex-direction: column;
            align-items: center;

            img {
                display: inline-block;
            }

            .plugin-additional-logos__logo-aiia {
                height: 22px;
                display: flex;
                align-self: flex-start;
                margin-left: -4px;
            }

            .plugin-additional-logos__logo-insurance {
                max-width: 99px;
            }

            .aiia-insurance-container {
                display: flex;
            }
        }
    }

    .expand-arrow-box {
        height: 40px;
    }

    .plugin-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .button-area-container {
        display: flex;
        flex-basis: 150px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        a {
            margin: 5px;
            font-size: 12px;
        }

        &.add-margin {
            margin-bottom: 26px;
        }
    }

    .plugin-desc-section {
        color: #797979;

        .toggle-desc {
            font-weight: 600;
            cursor: pointer;
            margin-left: 5px;
        }

        &.reduced {
            max-width: 370px;
        }
    }

    .node-edit-plugin {
        color: #807d7e;
        display: flex;
        flex-direction: column;
        font-size: 0.9em;

        div:nth-child(1) {
            margin-bottom: 10px;
        }

        span {
            text-decoration: underline;

            cursor: pointer;
        }
    }

    .btn-section-hint {
        margin-bottom: 10px;
        color: #807d7e;
    }

    @media screen and (max-width: 640px) {
        .plugin-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .plugin-logo-box {
            p {
                text-align: center;
                font-size: 14px;
            }
            img {
                display: block;
                margin: 0 auto;
                padding: 0;
                max-width: 240px;
            }
        }

        .service-price {
            padding: 10px 0;

            &.more-text {
                align-self: initial;
                flex: 0.4 0 85px;
            }
        }
    }

    @media screen and (max-width: 568px) {
        .plugin-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .button-area-container {
            flex-basis: auto;
        }

        .service-price {
            padding-bottom: 10px;
        }
    }
}
