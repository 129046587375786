#resurs-onboarding__details-step {
    padding-left: 10%;
    padding-right: 10%;

    .resurs-onboarding__store-information-title {
        font-size: 14px;
        margin-top: 2em;
    }

    .resurs-onboarding__store-information {
        display: flex;
        color: #333333;
        background-color: #f9f9f9;
        padding: 1em;
        margin-bottom: 2em;

        @media screen and (max-width: 400px) {
            flex-direction: column;
        }

        .resurs-onboarding__store-country-code {
            font-size: 15px;
            margin-right: 1em;
        }

        .resurs-onboarding__store-national-id {
            font-size: 15px;
            margin-right: 2em;
        }

        .resurs-onboarding__store-name {
            font-size: 15px;
            margin-right: 1em;
        }
    }

    .resurs-onboarding__contact-us-button {
        color: #797976;
    }

    .resurs-onboarding__contact-us-button:hover {
        color: #575755;
        text-decoration: underline;
        cursor: pointer;
    }

    .resurs-onboarding__paymentTypes {
        color: #333333;
        background-color: #f9f9f9;
        padding: 1em;

        .resurs-onboarding__paymentType {
            margin: 0.5em 0;
        }
    }
}
