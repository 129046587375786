@import "../ClearhausOnboardingForm.scss";

.clearhaus-company-fields {
    position: relative;

    .clearhaus-company-fields__company-review {
        display: flex;
        justify-content: space-between;

        .clearhaus-company-fields__contact-us {
            width: 100px;
            height: fit-content;
            color: #23638e;

            span {
                cursor: pointer;
                text-decoration: underline;
            }

            margin-right: 100px;
        }
    }

    .clearhaus-company-fields__row-city-postcode {
        max-width: $clearhaus-base-fields-width + 30px;
    }

    @include clearhaus-hint-breakpoint-laptop {
        .clearhaus-company-fields__company-review {
            flex-direction: column;
        }
    }

    @include clearhaus-breakpoint-tablet {
        .clearhaus-company-fields__row-city-postcode {
            & > div:first-child {
                padding-right: 0 !important;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .clearhaus-company-fields__row-city-postcode {
            & > div:first-child {
                padding-right: 15px !important;
            }
        }
    }
}