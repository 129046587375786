.container-with-close-button {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    background-color: white;

    &.closed {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .close-cross {
        position: absolute;
        top: 20px;
        right: 20px;

        transform: scale(0.8);
    }

    .header {
        &.cursor-pointer {
            cursor: pointer;
        }
    }
}
