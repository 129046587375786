.modal-merchant-offer-navigate-kyc {
    .modal-content {
        padding: 50px 70px 40px 70px;
    }

    .modal-dialog {
        max-width: 600px;
    }

    .modal-merchant-offer-navigate-kyc__intro {
        font-size: 14px;
        padding-bottom: 20px;
    }

    .modal-merchant-offer-navigate-kyc__box {
        font-size: 16px;
        background-color: rgba(255, 243, 205, 1);
        padding: 18px;
    }
}
