@import "../FormBaseStyles.scss";

.completed-fields-review {
    flex-grow: 1;
    max-width: $form-base-fields-width; //ipo: in future max-width based on class

    border: none;
    background-color: #eef0f6;
    color: #1a496d;
    font-size: 16px;

    .swiipe-files-list {
        margin-bottom: 0;
    }
}