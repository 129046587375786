#date-of-birth-input-container {
    .react-datepicker {
        font-family: inherit;

        &.datepicker-hidden {
            opacity: 0;
        }

        .date-range-edge {
            background-color: #153c64 !important;
            color: #fff !important;
        }

        .react-datepicker__month-dropdown-container {
            margin-right: 20px;
        }

        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__year-read-view--down-arrow {
            top: 3px;
        }

        a.react-datepicker__navigation.react-datepicker__navigation--years {
            &.react-datepicker__navigation--years-upcoming::after {
                transform: rotate(225deg);
                top: 15px;
            }

            &.react-datepicker__navigation--years-previous::after {
                transform: rotate(45deg);
                top: 6px;
            }

            &.react-datepicker__navigation--years-upcoming::after, 
            &.react-datepicker__navigation--years-previous::after {
                color: black;
                border-right: 1px solid currentcolor;
                border-bottom: 1px solid currentcolor;
                content: '';
                width: 8px;
                height: 8px;
                display: block;
                position: absolute;
                left: 12px;
            }
        }
    }
}