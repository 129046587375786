@import "../../scss/partials/_all.scss";
#app-container #main-container.merchant-dashboard-page {
    max-width: 1450px;
    width: auto;
    padding: 0;

    .merchant-dashboard-webshop-select {
        margin: 0 20px;
    }

    .merchant-dashboard-header {
        margin: 20px 20px 0 20px;
    }

    .grid-container {
        min-height: 300px;
    }

    .active-payments {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .service-activation-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        button {
            width: auto;
        }

        &.bind-right {
            justify-content: right;
            align-items: flex-end;

            button {
                margin-right: 20px;
            }
        }
    }

    .layout {
    }

    @media screen and (max-width: 785px) {
        .react-grid-layout {
            height: auto !important;
        }
        .react-grid-item {
            position: static !important;
            height: auto !important;
            transform: none !important;
            margin: 20px;
        }
    }
}
