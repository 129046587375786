@import "../../scss/partials/_all.scss";

.swiipe-accept-terms {
    &.in-form {
        font-size: 11px;
        color: #585858;
        a {
            color: $blue-header;
            font-weight: 600;

            &:hover {
                color: $blue-link-hover;
                text-decoration: none;
            }
        }
    }
}
