@import "../../scss/partials/colors.scss";

$border-gray: #d0d0d0;

#offerhistory {
    .table-container {
        border-top: 2px solid $blue-main;
    }

    .buttons-container {
        .btn-switch {
            display: inline-block;
            padding: 10px;
            cursor: pointer;
            background: white;
        }

        .active {
            color: white;
            background: $blue-main;
        }
    }

    .info-icon {
        vertical-align: sub;
        display: inline-block;
        margin-left: 4px;
        height: 15px;
        width: 15px;

        &:hover + .reason-refused {
            display: block;
        }
    }

    .nowrap {
        white-space: nowrap;
    }

    .table {
        font-size: small;

        thead {
            border: none;
        }

        th {
            border: 3px solid white;
            font-weight: 200;
            text-align: center;
            background: #eaf1f9;
            vertical-align: middle;
            white-space: nowrap;
            padding: 12px 6px;
        }

        td {
            text-align: center;
            vertical-align: middle;
        }

        .btn-outline-danger {
            font-size: smaller;
            padding: 0 8px;
            border-color: $border-gray;
        }
    }

    .reason-refused {
        background: white;
        padding: 15px;
        position: absolute;
        width: 270px;
        white-space: normal;
        text-align: left;
        border: 1px solid $border-gray;
        margin-top: 10px;
        display: none;
        z-index: 999;

        .text {
        }

        .name {
            display: block;
            text-align: right;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -6px;
            left: 58px;
            width: 10px;
            height: 10px;
            background: #ffffff;
            border-left: 1px solid #cad5e0;
            border-top: 1px solid #cad5e0;
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-title {
        font-size: large;
    }

    .modal-header {
        display: block !important;
    }

    .modal-body {
        .name {
            display: block;
            text-align: right;
        }

        .confirm-text {
            display: block;
        }

        button {
            float: right;
            font-size: small;
        }
    }
}

$breakpoint-tablet: 768px;
$breakpoint-mobile: 576px;

@media (max-width: $breakpoint-tablet) {
    #offerhistory {
        .modal-mask {
            margin-left: 119px;
        }
    }
}

@media (max-width: $breakpoint-mobile) {
    #offerhistory {
        .table {
            font-size: initial;
        }

        .modal-mask {
            margin-left: 49px;
        }
    }
}
