@import "../ClearhausOnboardingForm.scss";

.clearhaus-bank-account-fields {
    position: relative;
    display: flex;

    &--submitted {
        display: block;
    }

    .clearhaus-bank-account-fields__first-page-fields,
    .clearhaus-bank-account-fields__second-page-fields {
        flex-grow: 1;
    }

    .clearhaus-bank-account-fields__info-hint {
        margin-right: $clearhaus-hints-margin-right;
        margin-bottom: 1.5em;

        .clearhaus-bank-account-fields__hint-title {
            width: $clearhaus-hints-title-width;
        }
    }

    @include clearhaus-hint-breakpoint-laptop {
        flex-direction: column;

        .clearhaus-bank-account-fields__info-hint {
            max-width: $clearhaus-base-fields-width;
                
            .clearhaus-bank-account-fields__hint-title {
                flex-grow: 1;
            }
        }
    }
}