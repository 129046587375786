.modal-verify {
    .text-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal-verify-bold {
        font-weight: bold;
    }
    .modal-verify-error {
        color: red;
    }
    .modal-verify-input-container {
        margin-top: 20px;
    }
}
