.title-with-foldable-hint {
    display: flex;
    flex-direction: column;
    &.title-with-foldable-hint--has-hint {
        cursor: pointer;
    }

    .foldable-hint__title-container {
        z-index: 1;
        position: relative;
        &--text-align {
            &-left {
                text-align: left;
            }

            &-center {
                text-align: center;
            }
        }

        .foldable-hint__title {
            vertical-align: middle;

            &--font-size {
                &-14 {
                    font-size: 14px;
                }

                &-16 {
                    font-size: 16px;
                }

                &-20 {
                    font-size: 20px;
                }
            }
        }

        .foldable-hint__icon {
            vertical-align: middle;
            position: relative;
            margin-left: 5px;

            img {
                width: 16px;
            }

            &:after {
                content: " ";
                position: absolute;
                right: 8px;
                top: 34px;
                width: 10px;
                height: 10px;
                border-top: none;
                background-color: #edf0f5;
                transform: translate(50%, -50%) rotate(45deg);

                -webkit-box-shadow: 4px -3px 4px 0px #223c5033;
                -moz-box-shadow: 4px -3px 4px 0px #223c5033;
                box-shadow: 4px -3px 4px 0px #223c5033;
                z-index: 0;
            }

            &--hidden_arrow:after {
                content: none;
            }
        }
    }

    .foldable-hint__container {
        &--font-size {
            &-14 {
                font-size: 14px;
            }

            &-15 {
                font-size: 15px;
            }

            &-16 {
                font-size: 16px;
            }
        }

        &--font-weight {
            &-300 {
                font-weight: 300;
            }

            &-400 {
                font-weight: 400;
            }
        }

        margin: 0;
        max-width: 100%;
        z-index: 1;

        cursor: default;
        color: #1a496d;
        overflow: hidden;
        max-height: 0;
        padding: 0;

        transition: all 0.25s ease-in-out;

        &.foldable-hint__container--expanded {
            max-height: 99999px;
            padding: 15px;
            margin: 10px 0;
            transition: all 0.25s ease-in-out;
        }
    }

    &--theme-white {
        .foldable-hint__container {
            z-index: 0;
        }
        .foldable-hint__title-container {
            color: #797676;

            .foldable-hint__icon {
                &:after {
                    background-color: white;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    border-top: 1px solid #ced4da;
                    border-left: 1px solid #ced4da;
                    z-index: 1;
                    top: 32px;
                }
            }
        }

        .foldable-hint__container--expanded {
            border: 1px solid #ced4da;
        }

        .foldable-hint__container {
            color: #797676;
            background-color: white;

            border-radius: 5px;
            -webkit-box-shadow: 4px 4px 3px 0px #223c5033;
            -moz-box-shadow: 4px 4px 3px 0px #223c5033;
            box-shadow: 4px 4px 3px 0px #223c5033;
        }
    }

    &--theme-blue {
        $color-blue: #edf0f5;

        .foldable-hint__title-container {
            .foldable-hint__icon {
                &:after {
                    border-bottom: 15px solid $color-blue;
                }
            }
        }

        .foldable-hint__container {
            background-color: $color-blue;

            border-radius: 5px;
            -webkit-box-shadow: 4px 4px 3px 0px #223c5033;
            -moz-box-shadow: 4px 4px 3px 0px #223c5033;
            box-shadow: 4px 4px 3px 0px #223c5033;
        }
    }

    &--theme-grey {
        $color-grey: #f2f2f2;

        .foldable-hint__title-container {
            .foldable-hint__icon {
                &:after {
                    border-bottom: 15px solid $color-grey;
                }
            }
        }

        .foldable-hint__container {
            background-color: $color-grey;
        }
    }
}
