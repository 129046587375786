.create-pl-container {
    .create-pl-title {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0 30px;

        .checkmark-circle {
            margin-right: 10px;
        }

        &.closed {
            justify-content: left;

            p {
                text-decoration: underline;
            }
        }

        p {
            margin: 0;
            font-size: 1.4em;
        }
    }

    .pl-created {
        .summary {
            display: flex;
            justify-content: space-between;
            font-size: 1.2em;
            font-weight: 600;
            color: #555555;
        }

        .copy-link-group {
            .label {
                flex-basis: 100%;
                color: #555555;
                font-size: 1.2em;
            }
            
            input {
                font-weight: 500;
            }
        }


        .send-to-customer-btn,
        .copy-link-btn {
            height: 38px;
            width: auto;

            padding-left: 30px;
            padding-right: 30px;
        }

        .text-btn {
            font-size: 1.2em;
        }

        .sent-to-customer-text {
            color: #555555;
            font-style: italic;
            font-size: 1.1em;
        }
    }

    .create-pl-form {
        .submit-btn {
            max-width: 280px;
            margin: 0 auto;
        }

        .input-legend {
            color: #767679;
            font-size: 1.2em;
        }

        .checkbox {
            color: #767679;
            font-size: 1.2em;
        }

        .checkbox-container {
            .preview-receipt-btn {
                text-align: left;
                margin-left: 20px;

                .swiipe-spinner-container {
                    .spinner {
                        margin-left: 0;
                    }
                }

                span::before {
                    content: "(";
                }

                span::after {
                    content: ")";
                }
            }
        }

        .form-group,
        .checkbox-container {
            display: flex;

            .field-section {
                flex-grow: 1;
            }

            .hint-section {
                display: flex;
                align-items: center;
                justify-content: right;

                flex-basis: 35px;
                min-width: 35px;
            }
        }
    }

    .create-pl-form,
    .pl-created {
        padding: 0 25%;

        @media screen and (max-width: 685px) {
            padding: 0 10%;
        }

        @media screen and (max-width: 450px) {
            margin-top: 40px;
            padding: 0 5%;

            .submit-btn {
                max-width: 240px;
            }
        }

        @media screen and (max-width: 380px) {
            padding: 0 5%;

            .submit-btn {
                max-width: 200px;
            }
        }
    }
}
