.anywhere-mobile-onboarding {
    margin: 0 45px;

    .anywhere-mobile-onboarding__logo {
        text-align: center;
        margin: 20px 0 30px 0;

        img {
            width: 220px;
        }
    }

    .anywhere-mobile-onboarding__legend {
        background: #f2f2f2;
        padding: 30px 40px;
        font-size: 15px;
    }

    .anywhere-mobile-onboarding__form {
        margin: 0 40px;

        .anywhere-mobile-onboarding__submit-btn {
            max-width: 300px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 620px) {
        margin: 0 10px;
        .anywhere-mobile-onboarding__form {
            margin: 0 10px;
        }
    }

    @media screen and (max-width: 380px) {
        margin: 0;
        .anywhere-mobile-onboarding__form {
            margin: 0 15px;
        }
    }
}