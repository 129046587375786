.modal-standard-info {
    .modal-content {
        padding: 50px 70px 60px 70px;
    }

    .modal-dialog {
        max-width: 600px;
    }

    .swiipe-modal-header {
        margin-bottom: 28px;
    }

    .modal-standard-info__text {
        font-size: 16px;
        padding-bottom: 12px;
    }
}
