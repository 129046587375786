.clearhaus-single-comment {
    border: none;
    background-color: #f3f6ff;
    color: #1a496d;
    font-weight: 300;
    font-size: 16px;
    
    .clearhaus-single-comment__text {
        max-width: 80%;
    }

    .clearhaus-single-comment__date {
        margin-top: 20px;
        font-size: 12px;
        font-weight: 600;
        text-align: right;
    }
}