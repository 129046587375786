span.fixed-label {
    font-size: 1.2em;

    &.bold {
        font-weight: bold;
    }
}

.fixed-label-input {
    margin-top: 3px;
}
