@import "../../scss/partials/_all.scss";

.swiipe-link-button {
    &.swiipe-btn-dark
    {
        color: white
    }
}

.swiipe-link-href {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .p-link {
        color: $blue-main !important;
        display: inline-block;
        text-decoration: underline;
    }

    &:hover {
        text-decoration: none;

        .p-link {
            color: $blue-link-hover !important;
            border-color: $blue-link-hover;
        }
    }

    &.sandbox-link {
        p {
            color: #088488 !important;
        }
        text-decoration: underline;
        color: #088488;
    }
}
