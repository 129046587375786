@import "../../../scss/partials/_all.scss";

.swiipe-adder {
    .icon-addnew {
        background-image: url("../../../images/icons/addnew.png");
    }

    button {
        display: flex;
        align-items: center;

        .text-element {
            font-size: 0.8em;
            color: $blue-second;
        }
    }
}
