.drop-down-list-select {
    &.small {
        height: 28px;
        font-size: 0.8rem;
        padding: 0 0.5rem;
    }

    &.no-border-right {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.small-border-radius {
        border-radius: 3px;
    }
}
