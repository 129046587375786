.sw__date-range-picker {
    .sw__date-range-picker__from-to-input {
        color: #797676;
        padding-left: 25px !important;
        font-size: 13px !important;
        padding: 0;

        background: url("../../../../images/icons/calendar-days-solid.svg") no-repeat 5px;
        background-size: 12px;
    }

    .sw__date-range-picker__inputs-row {
        display: flex;
        align-items: center;
        padding: 15px 0;
    
        .sw__date-range-picker__input-title {
            margin: 0 10px
        }
    }

    .sw__date-range-picker__select-all-toggle {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0 15px 0;
        font-size: 14px;
    }

    // All date pickers
    .react-datepicker {
        font-family: inherit;

        &.react-datepicker--hidden {
            opacity: 0;
        }

        .date-range-edge {
            background-color: #153c64 !important;
            color: #fff !important;
        }

        .react-datepicker__month-dropdown-container {
            margin-right: 20px;
        }

        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__year-read-view--down-arrow {
            top: 3px;
        }

        a.react-datepicker__navigation.react-datepicker__navigation--years {
            &.react-datepicker__navigation--years-upcoming::after {
                transform: rotate(225deg);
                top: 15px;
            }

            &.react-datepicker__navigation--years-previous::after {
                transform: rotate(45deg);
                top: 6px;
            }

            &.react-datepicker__navigation--years-upcoming::after, 
            &.react-datepicker__navigation--years-previous::after {
                color: black;
                border-right: 1px solid currentcolor;
                border-bottom: 1px solid currentcolor;
                content: '';
                width: 8px;
                height: 8px;
                display: block;
                position: absolute;
                left: 12px;
            }
        }
    }

    //Date picker dropdown
    .react-datepicker__tab-loop {
        .react-datepicker {
            .react-datepicker__day--in-range {
                background-color: #f0f0f0;

                &.react-datepicker__day--in-selecting-range {
                    background-color: #153c64ba;
                }
            }
        }
    }

    //Date picker calendar
    .react-datepicker {
        .react-datepicker__day--in-range,
        .react-datepicker__day--in-selecting-range {
            background-color: #153c64ba;
        }

        .react-datepicker__day--keyboard-selected {
            &:not(.react-datepicker__day--in-selecting-range):not(.date-range-edge):not(.react-datepicker__day--in-range) {
                background-color: unset;
                color: #000;
            }
        }
    }
}