.merchant-payment-links-container {
    .payment-link-id-cell {
        padding-right: 10px;
        width: 110px !important;
    }

    .merchant-payment-links__navigation {
        display: inline-block;
    }

    .payment-links__table-cell {
        max-width: 185px;
        min-width: 60px;
    }

    .actions-table-container {
        display: flex;
        align-items: center;

        .text-btn {
            margin-left: 3px;
        }

        button {
            min-width: 110px;
            font-size: 14px;
        }

        .swiipe-spinner-container:first-child {
            padding-right: 5px;
        }
    }
}
