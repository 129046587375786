@import "../../../styles//partials/all";

.swiipe-search-box {
    background-color: white;
    margin: 20px 0;
    padding: 15px;

    input {
        background: url("../../../images/icons//search/search-icon.svg") no-repeat right 10px center;
        padding-right: 30px;

        &::placeholder {
            color: $gray-font;
            font-weight: 100;
        }
    }

    &.icon-left {
        input {
            background: url("../../../images/icons//search/search-icon.svg") no-repeat left 10px center !important;
            padding-left: 35px !important;
            padding-right: 0 !important;

            &::placeholder {
                color: $gray-font;
                font-weight: 100;
            }
        }
    }

    &.icon-blue {
        input {
            background: url("../../../images/icons//search/search-icon-blue.svg") no-repeat left 10px center !important;
        }
    }
}
