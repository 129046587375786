.modal-capture-refund {
    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .currency {
            padding-left: 10px;
        }
    }

    .buttons-container {
        display: flex;
        justify-content: flex-end;
    }
}
