@import "../../../../scss/partials/all";
.merchant-dashboard-header-latest-report-date {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    color: $gray-font;

    .merchant-dashboard-header-latest-report-date__title {
        font-style: italic;
    }
}