$form-base-fields-width: 435px;
$form-hints-margin-right: 45px;
$form-hints-title-width: 155px;
$form-documentation-left-margin: 140px;
$form-indent-normal: 320px;
$form-indent-smaller: 180px;

@mixin form-hint-breakpoint-laptop {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin form-breakpoint-tablet {
    @media screen and (max-width: 945px) {
        @content;
    }
}

@mixin form-breakpoint-laptop {
    @media screen and (max-width: 1110px) {
        @content;
    }
}
