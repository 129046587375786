@import "../../form/FormBaseStyles.scss";

.acquiring-business-model-fields {
    display: flex;

    .acquiring-business-model-fields__fields-content {
        max-width: $form-base-fields-width;
    }

    .acquiring-business-model-fields__fields {
        flex-grow: 1;
    }

    .acquiring-business-model-fields__info-hint {
        margin-right: $form-hints-margin-right;
        margin-bottom: 1.5em;

        .acquiring-business-model-fields__hint-title {
            width: $form-hints-title-width;
        }
    }

    .acquiring-business-model-fields__input {
        max-width: $form-base-fields-width;
    }

    @include form-hint-breakpoint-laptop {
        flex-direction: column;

        .acquiring-business-model-fields__info-hint {
            max-width: $form-base-fields-width;

            .acquiring-business-model-fields__hint-title {
                flex-grow: 1;
            }
        }
    }
}