@import "./FormBaseStyles.scss";

.form-header {
    position: relative;
    display: flex;
    align-items: center;

    .form-header__text {
        font-size: 1.35em;
        font-weight: 300;
        flex-grow: 1;
        margin: 0 50px 0 5px;
    }

    .form-header__back {
        padding-left: 75px;
        width: $form-indent-normal;
    }

    .form-header__close {
        position: absolute;
        right: 40px;
        top: 30px;
        width: 26px;
        height: 26px;
        opacity: 0.3;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
        &:before,
        &:after {
            position: absolute;
            left: 15px;
            content: " ";
            height: 26px;
            width: 1px;
            background-color: #333;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }

    @include form-breakpoint-tablet {
        .form-header__text {
            flex: 1 1 auto;
            text-align: center;
        }
        .form-header__back {
            align-items: center;
            width: auto;
            padding-left: 20px;
            padding-right: 10px;
            flex: 0 0 auto;
        }
    }

    @media screen and (max-width: 650px) {
        .form-header__text {
            margin-right: 20px;
        }

        .form-header__back {
            padding-left: 0;
        }

        .form-header__close {
            right: 20px;
        }
    }
}
