.swiipe-standard-box {
    background: white;

    &__width {
        &-narrow {
            max-width: 400px;
            margin: auto;
        }
    }

    &__margin {
        &-0 {
            margin: 0;
        }

        &-20-0 {
            margin: 20px 0;
        }
    }

    &__padding {
        &-15 {
            padding: 15px;
        }
        
        &-15-20 {
            padding: 15px 20px;
        }

        &-30-20 {
            padding: 30px 20px;
        }

        &-35-20 {
            padding: 35px 20px;
        }
    }    

    &__borders {
        &-rounded {
            border-radius: 7px;
        }
    }
}