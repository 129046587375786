@import "../../scss/partials/_all.scss";

.modal-confirm-dangerous-action {
    padding: 2rem 1.5rem 1rem 1.5rem;
    .modal__dialog-title {
        font-size: 18px;
    }

    .modal__dialog-title--is_danger {
        color: #cc0000;
    }

    .modal__dialog-text {
        font-size: 16px;
    }
}
