.merchant-dashboard-tile-overlay-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .merchant-dashboard-tile-overlay-content__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        opacity: 0.7;
    }

    .merchant-dashboard-tile-overlay-content__overlay-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}