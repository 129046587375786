.inline-elements-with-dividers {
    display: flex;

    > * {
        &:first-child {
            border-left: none;
            padding-left: 0;
        }

        padding: 0 10px;
        border-left: 1px solid #d7d7d7;
    }
}
