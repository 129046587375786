.customize-pl-notifications-container {
    .customize-notifications-title {
        display: flex;
        &.opened {
            justify-content: center;

            p {
                margin: 0;
                font-size: 1.4em;
            }
        }

        &.closed {
            justify-content: space-between;
            align-items: center;

            padding: 0 30px;

            .customize {
                img {
                    margin-right: 10px;
                }

                span {
                    text-decoration: underline;
                }
            }

            .configured {
                font-style: italic;
                font-size: 0.9em;
                color: #26880d;

                &.not {
                    color: #767679;
                }
            }
        }
    }

    .customize-notifications-form {
        .input-legend {
            color: #767679;
            font-size: 1.2em;

            &.bold {
                font-weight: 600;
            }
        }

        .webshop-name-field {
            input {
                color: #1a496d;
                font-weight: 500;
                background-color: #f2f2f27f;
            }
        }

        .current-logo {
            background-color: #f2f2f2;
            display: flex;
            align-items: center;
            padding: 15px 10px;

            .text {
                font-size: 1em;
            }

            .swiipe-files-list {
                margin-left: 10px;
                margin-bottom: 0;
                font-weight: 300;

                .file {
                    margin-top: 2px;
                }
            }
        }

        .group-placeholder {
            flex-basis: 100%;
            font-size: 1.1em;
            font-weight: 300;
            display: block;
            margin-bottom: 3px;
        }

        .form-group {
            display: flex;

            .field-section {
                flex-grow: 1;
            }

            .hint-section {
                display: flex;
                align-items: center;
                justify-content: right;

                flex-basis: 35px;
                min-width: 35px;

                &.bind-top {
                    align-items: flex-start;
                }
            }
        }

        .indented-box {
            margin-left: 110px;
            margin-right: 35px;

            @media screen and (max-width: 450px) {
                margin-left: 0;
            }
        }

        .preview-buttons {
            justify-content: center;

            .text-btn {
                font-size: 1.2em;
            }
        }
    }

    .customize-notifications-form {
        padding: 0 20%;

        @media screen and (max-width: 750px) {
            padding: 0 8%;
        }

        @media screen and (max-width: 550px) {
            margin-top: 40px;
            padding: 0 5%;
        }

        @media screen and (max-width: 450px) {
            .tabs {
                margin-bottom: 20px;
            }
        }
    }
}
