@import "../../scss/partials/_all.scss";

.billing-payment-methods {
    $light-grey: #797979;

    .none-found {
        font-size: 16px;
        font-weight: 300;
    }
    .add-payment-method {
        width: auto;
        margin-top: 10px;
        margin-bottom: 20px;
        display: inline-block;
    }
    .billing-payment-methods-inner-container {
        padding-top: 10px;
        margin-bottom: 10px;
    }
    .action-section {
        width: 160px;
        display: flex;
        justify-content: center;
        padding-right: 10px;

        button {
            width: auto;
            float: right;
        }
        span {
            float: right;
        }
        .set-primary {
            color: $light-grey !important;
            font-size: 12px;
            margin-right: 3px;
        }
        .delete {
            color: $red-error !important;
            font-size: 12px;
        }
        .primary-title {
            color: #23638e;
            font-size: 14px;
            font-weight: 600;
        }
        .delete,
        .set-primary {
            flex: 1 1 auto;
        }
        .billing-payment-methods__action-divider {
            background-color: $light-grey;
            align-self: center;
            opacity: 0.6;
            height: 15px;
            margin: 0 10px;
            flex: 0 0 1px;
        }
    }
    .billing-payment-method {
        display: flex;
        max-width: 500px;
        border-bottom: 1px solid #dfdfdf;
        align-items: center;
        padding-left: 3px;

        color: $light-grey;
        font-weight: 300;

        &.primary {
            color: inherit;
            font-weight: inherit;
        }

        .payment-method-container {
            flex: 1 1 0;
            margin-right: 20px;
        }

        &:hover {
            border-radius: 7px;
            background-color: #f5f5f5;
        }
    }

    @media screen and (max-width: 601px) {
        .billing-payment-method {
            flex-direction: column;

            .payment-method-container {
                width: 100%;
            }

            .action-section {
                width: 100%;
                justify-content: right;
                margin: 0 0 10px 0;
            }
        }
    }

    @media screen and (max-width: 450px) {
        .billing-payment-method {
            .payment-method-container {
                .card-date {
                    flex-basis: unset;
                    margin: 0;
                }
            }
        }
    }
}
