@import "../../scss/partials/all.scss";

.tool-tip-container {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    display: inline-block;
    position: relative;
    .tool-tip {
        position: absolute;
        display: none;
        top: 0;
        transform: translateY(-100%);
        background-color: white;
        border: solid 1px $gray-border;
        border-radius: 3px;
        padding: 4px;
        z-index: 1;

        &.no-wrap {
            white-space: nowrap;
        }
        &.center-y {
            top: 50%;
            transform: translateY(-50%);
        }

        -webkit-touch-callout: all; /* iOS Safari */
        -webkit-user-select: all; /* Safari */
        -khtml-user-select: all; /* Konqueror HTML */
        -moz-user-select: all; /* Old versions of Firefox */
        -ms-user-select: all; /* Internet Explorer/Edge */
        user-select: all; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    &:hover {
        .tool-tip {
            display: block;
        }
    }
}
