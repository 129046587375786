.partnersettings {
    .info-light {
        font-size: 0.9em;
        font-weight: 300;
    }

    .btn-change {
        width: 100px;
    }
}
