body {
    @media screen and (max-width: 768px) {
        .sparxpres-terms-logo {
            background-size: 12rem;
        }
    }

    @media screen and (max-width: 576px) {
        h3 {
            font-size: 1rem;
            word-break: normal;
            font-weight: 600;
        }
        .sparxpres-terms-logo {
            background-size: 10rem;
        }
    }
    @media screen and (max-width: 350px) {
        h3 {
            font-size: 0.8rem;
            word-break: normal;
            font-weight: 600;
        }

        .sparxpres-terms-logo {
            background-size: 8rem;
        }
    }
}

.sparxpres-terms-logo {
    background: url("../images/swPaymentTypes/sparxpres-x-swiipe-light-logo-da.png");
    height: 50px;
    background-size: 14rem;
    width: 250px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
}
