@import "../../styles/partials/all";

.icon-button {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.icon-button--type-add .icon-button__icon {
        background-image: url("../../images/icons/plusMinus/addNew.png");
    }

    &.icon-button--type-close .icon-button__icon {
        background-image: url("../../images/icons/close/close-blue.png");
    }

    .icon-button__icon {
        margin: 0 !important;
        height: 25px;
        width: 25px;
        background-size: 25px;
    }

    .icon-button__label {
        text-transform: uppercase;
        margin-left: 5px;
        font-size: 1em;
    }

    &.icon-button--small {
        .icon-button__icon {
            height: 20px;
            width: 20px;
            background-size: 20px;
        }
        .icon-button__label {
            font-size: 0.8em;
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        .icon-button__label {
            font-size: 0.8em;
        }
    }
}
