@import "../../../scss/partials/all.scss";

#sendoffer {
    /* custom styles */
    .smaller {
        font-size: smaller;
    }

    .pr-65px {
        padding-right: 65px !important;
    }

    .left-m-15 {
        left: -15px;
    }

    .close {
        font-size: 2em;

        &:focus,
        &:active {
            box-shadow: none !important;
            outline: 0px !important;
        }
    }

    .btn-custom {
        background: $blue-logo;
        color: #ffffff;

        &:hover {
            background: $blue-logo-dark;
            color: #ffffff;
        }
    }

    .form-footer {
        p {
            margin-bottom: 0px;
        }

        img {
            height: 14px;
        }
    }
    /* arrow box */
    .arrow-box {
        position: relative;
        background: #ffffff;
        border: 2px solid $blue-main;
    }

    .arrow-box:after,
    .arrow-box:before {
        bottom: 100%;
        left: 15%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .arrow-box:after {
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
    }

    .arrow-box:before {
        border-bottom-color: $blue-main;
        border-width: 13px;
        margin-left: -13px;
    }
    /* animation for showing the message */
    .expandable {
        div::before,
        div::after {
            display: none;
        }

        a {
            display: none;
        }

        overflow: hidden;
        transition: all 0.5s ease-in-out;
        max-height: 0;
    }

    .expand-show {
        div::before,
        div::after {
            display: block;
        }

        a {
            display: block;
        }

        max-height: 500px;
    }

    .manual-option {
        font-size: 16px;
    }
    .amount-input {
        padding-right: 65px;
    }
}
