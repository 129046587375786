@import "../../../scss/partials/_all.scss";

.swiipe-file-upload {
    width: 100%;
    background: #edf0f5;
    color: #7f7f7f;
    position: relative;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    border-radius: 8px;

    span {
        text-align: center;
        margin-top: 5px;
        margin-right: 10px;
    }

    .upload-btn {
        width: 50%;
        height: 40px;
        border-radius: 8px;

        &.slim-btn {
            height: fit-content;
            max-width: 90px;
            border-radius: $input-border-radius;
        }
    }

    &.light-blue-bg {
        background: rgba(238, 240, 246, 0.95);
    }

    input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }

    @media screen and (max-width: 355px) {
        flex-direction: column;
        padding: 10px 5px;

        .upload-btn {
            margin-top: 10px;
            width: 65%;
        }
    }
}
