.info-modal {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    .modal-title {
        color: #1a496d;
        font-size: 17px;
        font-weight: 450;
        padding: 0.4rem;
    }

    .modal-text {
        color: #0e3251;
    }

    .technical-details {
        color: #0e3251;
        font-size: 14px;
        padding: 0.4rem;

        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.3rem;
        }
        h3 {
            font-size: 1.2rem;
        }
        h4 {
            margin-top: 1.5rem;
            font-size: 1.1rem;
        }

        div {
            margin-bottom: 0.3rem;
        }
    }
}
