.go-back-container-clearhaus {
    margin-left: 73px !important;
    margin-right: 73px !important;
}

#clearhaus-details-page {
    .main-container {
        width: 560px !important;
        background-color: white;
        margin: auto;
        margin-bottom: 80px;
        padding: 50px 70px;
        border-radius: 0px;
    }

    .divider {
        max-width: 90%;
    }

    .option {
        background-color: #f2f2f280;
        color: #1a496dfa;
        padding-top: 15px;

        .main-title {
            font-weight: 600;
            font-size: 1.3em;
            text-align: center;
        }

        .price {
            text-align: center;
            font-size: 18px;
            padding: 15px 0 25px 0;
        }

        .swiipe-accept-terms {
            padding-bottom: 10px;
            margin: 0;

            a {
                color: #2a6fa4fa;
            }
        }
    }

    @media screen and (max-width: 720px) {
        .main-container {
            width: auto !important;
        }
    }

    @media screen and (max-width: 420px) {
        .main-container {
            width: auto !important;
            padding: 35px 15px;
        }
    }
}
