.applepay-onboarding-steps {
    .step {
        background-color: white;
        border-radius: 5px;
        padding: 25px;
        margin: 20px;
        display: flex;

        .step-header {
            width: 10%;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            color: #1a496d;
        }

        .step-content {
            margin-left: 5%;
            padding-right: 5%;
            flex-basis: 100%;
        }

        .applepay-summary {
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            a {
                text-decoration: underline;
                color: inherit;
            }
        }

        .applepay-guidelink {
            a {
                text-decoration: underline;
                color: #797979;
            }
        }
    }

    .step-centered {
        justify-content: center;
        padding-left: 10%;
        padding-right: 10%;
    }

    .applepay-submit-form {
        display: flex;
        flex-direction: column;

        .submit-btn {
            max-width: 280px;
            width: 50%;
            align-self: center;
        }
    }

    .applepay-download-btn {
        margin: 10px 0;
        max-width: 19em;
        height: fit-content;
        font-weight: 400;
    }

    .applepay-upload-input {
        width: 75%;
        margin-bottom: 10px;
    }
}
