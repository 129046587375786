@import "../../scss/partials/all.scss";

#top-navbar {
    padding-right: 130px;
    display: flex;

    .sandbox-label {
        color: white;
        background: #088488;
        font-weight: 600;
        padding: 5px 15px;
        border-radius: $input-border-radius;
        vertical-align: middle;
        font-size: 0.8em;
    }

    @media screen and (max-width: 400px) {
        .sandbox-label {
            font-size: 0.5em;
        }

        .navbar-brand {
            img {
                height: 60% !important;
                margin-top: 0.7rem !important;
            }
        }
    }

    .top-navbar__spacer {
        flex: 1 1 0;
    }

    .top-navbar__navigation-container {
        margin-right: 20px;
    }
}
