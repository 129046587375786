.hint-icon-with-text-on-hover {
    position: relative;

    .icon-hint {
        background: url("../../images/icons/info/info-inverted.svg") no-repeat;
        height: 16px;
        width: 16px;
        display: inline-block;
        vertical-align: sub;
        margin-left: 5px;
        cursor: pointer;
    }

    .text-hint {
        position: absolute;
        left: -215px;
        top: 25px;
        background: #383838f2;
        color: white;
        border-radius: 3px;
        padding: 10px 10px;
        z-index: 999;
        font-size: 12px;
        font-style: italic;

        &.blue {
            color: #1A496D;
            background-color: #eef0f6;
            border: 1px solid #e0e0e0;
            font-size: 14px;
            text-align: center;
            font-style: normal;
        }

        &.wider {
            width: 340px;
        }

        &.show-on-top {
            top: -5px;
            left: 60px;
            transform: translate(-100%, -100%);
        }
    }
}